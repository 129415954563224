:root {
  --primary-yellow: #ffeb3b;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-white: #fff;
  --primary-black: #000;
}

body {
  font-size: 16px;
  color: var(--primary-white) !important;
  background-color: #000 !important;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

.tel-contact-link {
  color: #fff !important;
  text-decoration: none;
}

/****************** NAVBAR *******************/
/***** MENU LINKS *****/
nav a.nav-link, .navbar-brand {
  font-weight: bold;
  letter-spacing: 0.038rem;
  cursor: pointer;
}

.tel-contact {
  font-weight: bold;
  text-align: center;
  margin-left: 15px;
}

.tel-contact a {
  color: #fff !important;
  text-decoration: none;
}

.navbar-nav > li > a, .navbar-brand {
  color: var(--primary-yellow) !important;
  border-bottom: 0.1875rem solid var(--primary-dark);
  transition: all .3s ease 0s;
}

.navbar-nav > li > a:after, .navbar-brand:after {
  position: absolute;
  content: '';
  bottom: .2em;
  left: 0;
  width: 100%;
  height: 0.063rem;
  opacity: 0;
  background: var(--primary-yellow);
  transition: all .3s ease 0s;
}

.navbar-nav > li > a:visited, .navbar-brand:visited {
	border: 1px solid var(--primary-yellow);
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus, .navbar-brand.active, .navbar-brand:focus {
  border-bottom: 0.1875rem solid var(--primary-yellow);
}

.navbar-nav > li > a:hover, .navbar-brand:hover {
  border-bottom: 0.1875rem solid var(--primary-yellow);
  cursor: pointer;
  transform: scale(1.1);
}

.navbar-nav > li > a:hover:after, .navbar-brand:hover:after {
	bottom: 0;
	opacity: 1;
}

.nabBarUl {
  margin-left: auto;
}

.navbarBG {
  background-color: var(--primary-black);
}

.nav-sub-link {
  color: var(--primary-white) !important;
}

.navIcon {
  color: var(--primary-yellow);
}

.main-logo {
  font-size: 2rem !important;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before { background-color: var(--primary-yellow); }

@media screen and (max-width: 1000px) {
  .tel-contact {
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .main-logo {
    font-size: 1.5rem !important;
  }
}

/****************** MAIN PAGE *******************/

/***** CAROUSEL *****/

.home-area {
  position: relative;
  height: 600px;
  overflow: hidden;
}

.top-image {
  min-height: 620px;
  opacity: 0.8;
  z-index: 1;
  object-fit: cover;
}

.top-image-stretch {
  object-position: 50% 100%;
}

.home-text {
  position: absolute;
  top: 0.313rem;
  left: 1.25rem;
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
  z-index: 10;
  letter-spacing: 0.3em;
}

.home-text2 {
  position: absolute;
  bottom: 0.313rem;
  right: 1.25rem;
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
  z-index: 10;
  letter-spacing: 0.3em;
}

@media screen and (max-width: 1000px) {
  .home-text2 {
    bottom: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .home-area {
    height: 300px;
  }

  .top-image {
    min-height: 300px;
  }

  .home-text {
    right: 0.6rem;
    font-size: 1rem;
  }

  .home-text2 {
    bottom: 0.8rem;
    right: 0.6rem;
    font-size: 1rem;
  }
}

/***** STORE NAME *****/

.store-name {
  letter-spacing: 1.5em;
  font-size: 3rem;
  font-weight: 650;
  margin: 0;
  padding: 0;
  border-bottom: 5px var(--primary-yellow) solid;
  border-top: 5px var(--primary-yellow) solid;
  text-align: center;
  color: var(--primary-yellow);
  }

  @media screen and (max-width: 1000px) {
    .store-name {
      letter-spacing: 1.2em;
      font-size: 1.8rem;
      font-weight: 600;
      }
  }

  @media screen and (max-width: 768px) {
    .store-name {
      letter-spacing: 1.2em;
      font-size: 1.4rem;
      border-bottom: 4px var(--primary-yellow) solid;
      border-top: 4px var(--primary-yellow) solid;
      }
  }

  @media screen and (max-width: 480px) {
    .store-name {
      font-size: 1.2rem;
      border-bottom: 3px var(--primary-yellow) solid;
      border-top: 3px var(--primary-yellow) solid;
      }
  }

  @media screen and (max-width: 375px) {
    .store-name {
      font-size: 1rem;
      }
  }

/***** MESSAGE & INTRODUCE *****/

  .message-area {
    margin: 100px 0;
    overflow: hidden;
    text-align: center;
  }

  .sub-message {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.3em;
    text-align: left;
    display: inline-block;
  }

  @media screen and (max-width: 1000px) {
    .sub-message, .introduce {
      font-size: 1.8rem;
    }

    .trainer-image {
      width: 250px;
      height: 250px;
      margin: 0 30px;
    }
  }

  @media screen and (max-width: 768px) {
    .sub-message {
      font-size: 1.4rem;
    }

    .introduce {
      font-size: 1.2rem;
    }

    .trainer-image {
      width: 200px;
      height: 200px;
      margin: 0 30px;
    }
  }

  @media screen and (max-width: 480px) {
    .message-area {
      margin: 30px 0;
    }
    
    .sub-message {
      font-size: 1rem;
      letter-spacing: 0.3em;
    }

    .sub-message-2 {
      font-size: 1.3rem;
    }
  }

  @media screen and (max-width: 375px) {    
    .sub-message {
      font-size: 0.8rem;
    }

    .sub-message-2 {
      font-size: 1.1rem;
    }
  }

/***** FREE TRY APPLY BUTTON *****/

.freetry-apply-button {
  background-color: var(--primary-yellow) !important;
  margin: 100px auto 30px;
  width: 100%;
  height: 100px;
  display: block;
}

.freetry-apply-button:hover {
  opacity: 0.7;
  background-color: var(--primary-yellow);
}

.free-apply {
  color: #333;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0.5rem;
  text-decoration: none;
}

.free-apply:hover {
  color: #333;
}

.tel-contact-home {
  text-align: center;
  font-size: 2rem;
}

.tel-contact-home a {
  text-decoration: none;
  color: #fff;
}

.tel-contact-home a:hover {
  color: #fff;
  opacity: 0.7;
}

  @media screen and (max-width: 768px) {
    .freetry-apply-button {
      height: 80px;
    }

    .tel-contact-home {
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 480px) {
    .freetry-apply-button {
      margin: 50px auto 20px;
      height: 50px;
    }
    
    .free-apply {
      font-size: 1.2rem;
      letter-spacing: 0.3rem;
    }

    .tel-contact-home {
      font-size: 0.9rem;
    }
  }

/***** ROCK GYM GOOD INFO *****/
  .rockgym-good {
    width: 100%;
    margin-top: 200px;
  }

  .main {
    display: block;
    width: 100%;
    height: 100px;
    color: #eee;
    font-size: 4rem;
    font-family: arial;
    font-weight: bold;
    margin: auto;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .main .text {
    display: block;
    width: inherit;
    height: inherit;
    color: #eee;
    font-size: 4rem;
    font-family: arial;
    font-weight: bold;
    left: 2px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    animation-name: textBlink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .shadow {
    position: absolute;
    width: inherit;
    height: 40px;
    overflow: hidden;
    animation-name: shadowDown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .shadow .viewport {
    position: relative;
    width: inherit;
    height: 200px;
  }

  .shadow .text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    animation-name: shadowTextUp;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  @keyframes textBlink {
    0% {
      opacity: 1;
      left: 2px;
    }
    30% {
      opacity: .7;
    }
    70% {
      opacity: .8;
      left: -2px;
    }
    71% {
      opacity: .8;
      left: 0px;
    }
    75% {
      opacity: .8;
      left: 1px;
    }
    100% {
      opacity: 7;
      left: 0px;
    }
  }

  @keyframes shadowDown {
    0% {
      top: 0px;
    }
    10% {
      top: 20px;
    }
    20% {
      top: 30px;
    }
    40% {
      top: 35px;
    }
    60% {
      top: 50px;
    }
    70% {
      top: 20px;
    }
    100% {
      top: 10px;
    }
  }

  @keyframes shadowTextUp {
    0% {
      top: 0px;
    }
    10% {
      top: -20px;
      opacity: .5;
    }
    20% {
      top: -30px;
      opacity: 1;
    }
    40% {
      top: -35px;
    }
    60% {
      top: -50px;
      opacity: .5;
    }
    70% {
      top: -20px;
    }
    100% {
      top: -10px;
      opacity: 1;
    }
  }

  .flex-item {
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
  }

  .flex-item:not(:last-child) {
    margin-bottom: 50px;
  }

  .flex-item:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .flex-item__img {
    width: 50%;
    align-items: center;
  }

  .flex-item__img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    box-shadow: 15px 15px 0 var(--primary-yellow);
  }

  .flex-item__txt {
    width: 50%;
    padding: 50px;
    display: table;
  }

  .flex-item__txt p {
    font-size: 1.2rem;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }

  .good-sub-title {
    font-size: 1.6rem;
    border-bottom: 3px var(--primary-yellow) solid;
  }

  .good-sub-title-no {
    font-size: 2.5rem;
    padding: 0 10px;
    margin-right: 10px;
    background-color: #fff;
    color: #333;
  }

  @media screen and (max-width: 1000px) {
    .flex-item__img img {
      height: 300px;
      box-shadow: 10px 10px 0 var(--primary-yellow);
    }

    .main, .main .text {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 768px) {
    .rockgym-good {
      margin-top: 150px;
    }

    .flex-item {
      display: block;
      margin: 100px auto;
    }

    .flex-item__img {
      width: 100%;
    }

    .flex-item__img img {
      height: 300px;
      box-shadow: 10px 10px 0 var(--primary-yellow);
    }

    .flex-item__txt {
      width: 100%;
      padding: 0 0 30px;
    }

    .flex-item__txt p {
      font-size: 1rem;
    }

    .good-sub-title {
      font-size: 1.4rem;
    }

    .good-sub-title-no{
      font-size: 2rem;
    }

    .main, .main .text {
      font-size: 2.5rem;
    }

    .flex-item-first {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 480px) {
    .rockgym-good {
      margin-top: 100px;
    }

    .flex-item {
      margin: 80px auto;
    }

    .flex-item__img {
      width: 90%;
      margin: 0 auto;
    }

    .flex-item__img img {
      height: 200px;
      box-shadow: 5px 5px 0 var(--primary-yellow);
    }

    .flex-item__txt {
      width: 90%;
      margin: 0 auto;
    }

    .flex-item__txt p {
      font-size: 0.8rem;
    }

    .good-sub-title {
      font-size: 1.2rem;
    }

    .good-sub-title-no{
      font-size: 1.5rem;
    }

    .main, .main .text {
      font-size: 1.8rem;
    }
  }

/***** GYM INTERIOR *****/

.gym-description {
  margin: 50px auto 150px;
  text-align: center;
}

.gym-items {
  display: flex;
  margin: 100px 0;
}

.gym-item-subarea {
  display: table;
  padding: 0 30px;
  width: 40%;
}

.gym-item-subarea span {
  font-size: 2rem;
  font-family: 'Yuji Syuku', serif;
}

.gym-description p {
  font-size: 1.4rem;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
}

.gym-description h2 {
  font-weight: bold;
}

.gym-interior {
  border-radius: 30px;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .gym-item-subarea {
    width: 45%;
  }

  .gym-item-subarea span {
    font-size: 1.6rem;
  }

  .gym-description p {
    font-size: 1.2rem;
  }

  .gym-interior {
    border-radius: 15px;
    width: 55%;
  }
}

@media screen and (max-width: 480px) {
  .gym-items {
    display: block;
    margin: 30px 0;
  }

  .gym-item-subarea {
    width: 90%;
    margin: 0 auto 15px;
  }

  .gym-item-subarea span {
    font-size: 1.4rem;
  }

  .gym-description p {
    margin: 0 auto 15px;
    font-size: 1rem;
  }

  .gym-interior {
    width: 75%;
  }
}


/***** PRICE *****/
.price-area{
  margin: 100px auto 0;
}

.price-area > h2 {
  text-align: center;
}

.course-item {
  margin: 30px auto;
  padding: 0 50px;
  border: solid var(--primary-white) 1px;
  height: 550px;
  display: table;
  width: 80%;
  text-align: center;
  position: relative;
}

.course-item-sub {
  display: table-cell;
  vertical-align: middle;
}

.course-title {
  font-size: 2.2rem;
  font-weight: bold;
  /* margin-top: 30px; */
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.price {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  /* margin: 30px auto; */
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.price span {
  font-size: 1rem;
}

.course-30  {
  margin-bottom: 15px;
}

.course-description, .course-subtitle {
  font-size: 1.2rem;
  margin: 0;
}

.container-course {
  display: flex;
  gap: 10px; /* 画像間の余白 */
  justify-content: center; /* 中央寄せ (変更可能) */
  align-items: center; /* 縦方向の中央揃え */
  flex-wrap: nowrap; /* 画面幅が狭い場合折り返す */
  padding-bottom: 30px;
}

.container-course img {
  width: 50%; /* 画像サイズを適切に調整 */
  max-width: 300px; /* 必要なら最大幅を制限 */
  height: auto;
  object-fit: cover; /* 画像の比率を維持 */
}

@media screen and (max-width: 768px) {
  .course-item {
    /* margin: 30px auto; */
    padding: 15px;
    /* height: 400px; */
  }

  .course-title, .price {
    font-size: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .price-area{
    margin-top: 30px;
  }
  
  .course-item {
    margin: 30px auto;
    padding: 15px;
    height: 400px;
  }
  
  .course-title{
    font-size: 1.3rem;
    width: 100%;
  }
  
  .price {
    font-size: 1.4rem;
    width: 100%;
  }

  .price span {
    font-size: 0.7rem;
  }
  
  .course-30 {
    margin-bottom: 15px;
  }
  
  .course-description, .course-subtitle {
    font-size: 0.875rem;
  }
}

/***** STORE MAP *****/

.map {
  margin: 100px auto;
  text-align: center;
}

.map > p {
  font-size: 1.3rem;
}

.map a {
  text-decoration: none;
  color: #fff;
}

.map a:hover {
  color: #fff;
  opacity: 0.7;
}

.map-frame {
  width: 80%;
  height: 500px;
}

@media screen and (max-width: 480px) {
  .map {
    margin: 50px auto;
  }
  
  .map > p {
    font-size: 0.875rem;
  }

  .map-frame {
    width: 90%;
    height: 300px;
  }
}

/***** FOOTER *****/

.footer {
  clear:  both;
  margin: 0 calc(50% - 50vw);
  text-align: center;
}

.footer-item {
  margin: 0;
}

.copyright {
  border-top: 1px solid var(--primary-white);
  padding: 30px;
}

.tel-link {
  text-decoration: none;
  color: #fff;
}

.tel-link:hover {
  color: #fff;
  opacity: 0.7;
}

.footer-freetry-apply-button {
  background-color: var(--primary-yellow) !important;
  margin: 50px auto 15px;
  width: 350px;
  height: 60px;
  display: block;
}

.footer-freetry-apply-button:hover {
  opacity: 0.7;
  background-color: var(--primary-yellow);
}

.footer-free-apply {
  color: #333;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 0.3rem;
  text-decoration: none;
}

.footer-free-apply:hover {
  color: #333;
}

.sns-icon-list {
  margin-bottom: 30px;
}

.sns-icon {
  color: var(--primary-white);
  margin: 0 30px;
  transition: 0.3s;
  font-size: 2.5rem;
}

.sns-icon:hover {
  transform: scale(1.2);
  transition-duration: 0.3s;
}

@media screen and (max-width: 480px) {
  .footer-freetry-apply-button {
    height: 50px;
  }

  .footer-free-apply {
    font-size: 1.2rem;
  }

  .copyright {
    font-size: 0.75rem;
  }
}

/****************** RESULT PAGE *******************/

.container > h2 {
  text-align: center;
  font-weight: bold;
}

.result-image {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.result-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  width: 70%;
  display: block;
  margin: 0 auto;
}

.result-info {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  width: 70%;
  display: block;
  margin: 0 auto;
  padding-left: 30px;
}

.result-box {
  margin: 30px auto;
  width: 500px;
}

.result-before-after {
  text-align: center;
  margin-top: 10px;
  font-size: 1.5rem;
  width:50%;
  display: inline-block;
}

.more-sns-icon {
  margin: 100px auto 50px;
  text-align: center;
}

.more-sns-icon > a {
  text-decoration: none;
  color: var(--primary-white);
  font-size: 1.5rem;
}

.more-sns-icon > a:hover {
  opacity: 0.5;
  color: var(--primary-white);
}

@media screen and (max-width: 480px) {
  .result-box {
    margin: 15px auto 30px;
    width: 85%;
  }

  .result-title {
    font-size: 1.2rem;
    margin-left: 20px;
  }

  .result-info {
    font-size: 1rem;
    width: 70%;
    padding: 0;
  }

  .more-sns-icon {
    margin: 50px auto 15px;
    text-align: center;
  }
  
  .more-sns-icon > a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 375px) {
  .result-before-after {
    font-size: 1.2rem;
  }
}

/****************** STORE PAGE *******************/

.store-info {
  width: 80%;
  margin: 0 auto;
}

.store-info > h2 {
  text-align: center;
  font-weight: bold;
  margin: 50px auto;
}

.store-info-item {
  display: flex;
  border-top: 1px solid var(--primary-white);
  text-align: center;
  height: 70px;
  font-weight: bold;
}

.store-info-item p {
  width: 50%;
  display: inline-block;
  margin: 0;
  padding-top: 20px
}

.store-info-item-dif {
  padding-top: 10px !important;
}

.interior-image {
  padding: 30px 0;
}

.interior-image img {
  width: 60%;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 1000px) {
  .store-info-item {
    height: 90px;
  }

  .store-info-item p {
    padding-top: 30px
  }
}

@media screen and (max-width: 480px) {
  .store-info {
    width: 80%;
  }

  .store-info-item {
    height: 70px;
  }

  .store-info-item p {
    font-size: 0.75rem;
    padding-top: 25px;
  }

  .store-info-item-dif {
    padding-top: 10px !important;
  }

  .interior-image {
    padding: 30px 0 0;
  }

  .interior-image img {
    width: 80%;
  }
}

/****************** PREVENTION PAGE *******************/

.prevention-area {
  width: 80%;
  margin: 0 auto;
}

.page-title {
  margin: 50px 0;
  text-align: center;
  font-weight: bold;
}

.prevention-box {
  text-align: center;
}

.prevention-item {
  display: flex;
  margin-bottom: 100px;
}

.prevention-title {
  color: #333;
  font-weight: bold;
  background-color: var(--primary-white);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  line-height: 200px;
  margin: 0 auto;
  font-size: 1.5rem;
}

.prevention-description {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}

.half-box {
  width: 50%;
  display: table;
}

.reverseItem {
  flex-flow: row-reverse;
}

@media screen and (max-width: 1000px) {
  .prevention-title {
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}

@media screen and (max-width: 480px) {
  .prevention-area {
    width: 90%;
  }

  .page-title {
    margin: 30px 0;
  }

  .prevention-item {
    margin-bottom: 60px;
  }

  .prevention-title {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 1rem;
  }

  .prevention-description {
    font-size: 0.875rem;
  }
}

/****************** CONTACT PAGE *******************/

#contact-form {
  margin: 50px auto 100px;
}

.contact-form {
  margin: 0 auto;
}

.contact-form th {
  text-align: left;
}

.contact-form td {
  text-align: left;
}

.contact-form span {
  color: #f33;
}

.contact-form td {
  height: 50px;
}

.input-text {
  margin-right: 15px;
  width: 200px;
  height: 30px;
  border-radius: 5px;
}

.input-select {
  margin-right: 15px;
  width: 200px;
  height: 30px;
  border-radius: 5px;
}

.input-textarea {
  width: 420px;
  height: 150px;
  border-radius: 5px;
}

.submit-button {
  width: 200px;
  height: auto;
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--primary-yellow);
  font-size: 1.2rem;
  margin: 30px auto;
  text-align: center;
  display: block;
}

.warning-for-submit {
  width: 70%;
  margin: 0 auto;
  font-weight: bold;
}

.sex-area {
  display: flex;
  justify-content: center;
}

.sex-box {
  margin: auto 30px;
}

.hope-time {
  text-align: left;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  #contact-form {
    margin: 50px auto 50px;
  }

  .contact-form th {
    font-size: 0.875rem;
  }

  .input-text {
    margin-right: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 30px;
  }
  
  .input-select {
    margin-right: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 30px;
  }
  
  .input-textarea {
    width: 100%;
    height: 150px;
  }
  
  .submit-button {
    width: 200px;
    height: auto;
    color: var(--primary-black);
  }

  .warning-for-submit {
    font-size: 12px;
  }
  
  .sex-box {
    margin: auto 15px;
  }
}

/****************** THANKS PAGE *******************/

.thanks-content {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 200px auto;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .thanks-content {
    font-size: 0.875rem;
    margin: 100px auto;
  }
}

.tel-contact-link {
  color: #fff !important;
  text-decoration: none;
}

.tel-contact a:hover {
  color: #fff;
  text-decoration: none;
}